.typedContainer {
    display: inline-block;
    height: 350px !important;
    width: 800px;

    span {
        // display: inline-block;
        // height: 350px !important;
        // width: 800px;
        // position: relative;
    }

    span::after {
        // content: "";
        // display: inline-block;
        // background-image: url("../../images/icon/cursor.svg");
        // background-repeat: no-repeat;
        // // background-size: 30px;
        // position: absolute;
        // width: 30px;
        // height: 30px;
    }

    span.typed-cursor {
        display: inline;
    }
}