.domainsList .MuiTableBody-root {
    .MuiTableRow-root:nth-child(odd) {
        background-color: #dbdbdb26;
    }
}

.MuiTableCell-root {
    white-space: nowrap;

    padding: 9px !important;
}