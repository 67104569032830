.link-action {
  text-decoration: none !important;
  text-transform: none !important;
}

html,
body,
#root {
  height: 100vh;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e9e9e900;
  }

  *::-webkit-scrollbar {
    background-color: #e9e9e900;
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d4d4d4;
  }
}

@media print {
  html,
  body {
    display: none;
  }
}

body {
  user-select: none;
  margin: 0;
  padding-right: 0 !important; // more info https://github.com/mui-org/material-ui/issues/10000;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#gear {
  width: 150px;
  animation-name: ckw;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
}
@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inputsWrap {
  display: flex;
  justify-content: space-between;

  .MuiFormControl-root {
    flex: 1;
  }

  .MuiFormControl-root:first-child {
    margin-right: 15px !important;
  }
}

.formContainer {
  .MuiFormControl-root {
    margin-bottom: 15px !important;
  }

  button {
    width: 100%;
  }
}

.pathSpinner {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-name: rotate;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

//toast styles
.Toastify__toast-container {
  width: auto;
}

.Toastify__toast {
  font-size: 23px !important;
  padding: 15px !important;
}

.Toastify__toast-body {
  margin-right: 13px !important;
}
//end toast styles

.MuiBackdrop-root {
  background-color: #000000a3;
}

.MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
}

.billingItem {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 10px;
  //font-weight: bold;

  div {
    font-weight: normal;
    color: #797979;
  }
}

.billingItemLast {
  margin-bottom: 20px;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  background-color: white !important;
}
